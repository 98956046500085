import {initQtyPickers} from './quantity-picker';
import {initPtsToggles} from './points-toggle';

document.addEventListener('DOMContentLoaded', () => {
  const quantityPickers = document.querySelectorAll("form[data-submit-on-change=true] [data-qty-picker] input");
  const pointsToggles = document.querySelectorAll("form[data-submit-on-change=true] [data-pts-toggle] input");

  if (quantityPickers.length > 0) {
    document.body.addEventListener("ajax:success", initQtyPickers);
  }

  if (pointsToggles.length > 0) {
    document.body.addEventListener("ajax:success", initPtsToggles);
  }
});
